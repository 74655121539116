/* .header{
    width: 100%;
    height: 56px;
    background: #2874f0;
} */
/* .subHeader{
    max-width: 1100px;
    width: 100%;
    height: 100%;
    display: flex;
    margin: 0 auto;   
    align-items: center;
} */

/* .logo{
    width: 137px;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
} */
 /* .logoimage{
    width: 75px;
} */
/* .exploreText{ 
    font-size: 10px;
    font-style: italic; 
}
.plusText{ 
    font-size: 10px; 
    margin: 0 2px; 
    text-transform: capitalize;
    font-style: italic;
    color: yellow;
  }
.logo .goldenStar{
    width: 10px;
    height: 10px;
} */

/* .searchInputContainer {
    width: 560px;
    position: relative;
    background: #fff;
    height: 36px;
    border-radius: 1px;
    overflow: hidden;
    display: flex;
  }
.searchInput {
    width: 518px;
    border: 0;
    height: 36px;
    outline: none;
    box-sizing: border-box;
    padding: 0 10px;
  } */
/* .searchIconContainer {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
  } */
 .rightMenu{
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: center;
  } 
 /* .loginButton {
    display: block;
    width: 100px;
    height: 30px;
    background: #fff;
    text-align: center;
    line-height: 30px;
    color: #2874f0;
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;
  } */
.more, .fullName {
    font-weight: bold;
    color: #fff;
    display: flex;
    align-items: center;
    margin: 0 20px;
    cursor: pointer;
  }
/* .cart {
    font-weight: bold;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 20px
  } */

.authContainer{
  color: #212121;
  margin: 0 auto;
  border-radius: 2px;
  height: 528px;
  max-width: 750px;
  min-width: 650px;
}
.header .authContainer .row1{
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  height: 528px;
  max-width: 750px;
  min-width: 650px;
}

.leftspace{
  width: 40%;
  height: 100%;
  color: #fff;
  letter-spacing: 1px;
  background-image: url('../../../images/login-image.png');
  background-color: #2874f0;
  background-repeat: no-repeat;
  background-position: center 85%;
  padding: 40px 33px;
  box-sizing: border-box;
}

.rightspace{
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
}

.loginInputContainer{
  width: 100%;
  box-sizing: border-box;
  padding: 0 30px;
  margin: 40px 0;
}


/* coin */
.coin {
  height: var(--coin-size);
  width: var(--coin-size);
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.coin::before {
  content: '';
  display: block;
  position: relative;
  height: var(--coin-size);
  width: var(--coin-size);
  border-radius: 50%;
  background-color: var(--face);
  animation: spin 1s linear infinite;
  background-image: var(--coin-face);
  background-size: 100% 100%;
  background-position: center;
  background-blend-mode: overlay;
}

.coin.flip::before {
  animation-name: flip;
}

.coin.copper::before {
  filter:
    hue-rotate(-40deg)
    brightness(0.75)
    contrast(115%);
  animation-delay: -0.25s;
}

.coin.silver::before {
  filter: saturate(0);
  animation-delay: -0.5s;
}

/* animation definitions */

@keyframes spin {
  0% {
    width: var(--coin-size);
    box-shadow:
      0 0 0 var(--side-dark);
    animation-timing-function: ease-in;
  }
  
  49.999% {
    width: 0.1rem;
    box-shadow:
      0.05rem 0 0 var(--side),
      0.1rem 0 0 var(--side),
      0.15rem 0 0 var(--side),
      0.2rem 0 0 var(--side),
      0.25rem 0 0 var(--side),
      0.3rem 0 0 var(--side),
      0.35rem 0 0 var(--side),
      0.4rem 0 0 var(--side),
      0.45rem 0 0 var(--side),
      0.5rem 0 0 var(--side),
      0.55rem 0 0 var(--side),
      0.6rem 0 0 var(--side),
      0.65rem 0 0 var(--side),
      0.7rem 0 0 var(--side),
      0.75rem 0 0 var(--side);
    transform: translateX(-0.375rem);
    background-color: var(--lowlight);
    animation-timing-function: linear;
  }
  
  50.001% {
    width: 0.1rem;
    box-shadow:
      -0.05rem 0 0 var(--side),
      -0.1rem 0 0 var(--side),
      -0.15rem 0 0 var(--side),
      -0.2rem 0 0 var(--side),
      -0.25rem 0 0 var(--side),
      -0.3rem 0 0 var(--side),
      -0.35rem 0 0 var(--side),
      -0.4rem 0 0 var(--side),
      -0.45rem 0 0 var(--side),
      -0.5rem 0 0 var(--side),
      -0.55rem 0 0 var(--side),
      -0.6rem 0 0 var(--side),
      -0.65rem 0 0 var(--side),
      -0.7rem 0 0 var(--side),
      -0.75rem 0 0 var(--side);
    transform: translateX(0.375rem);
    background-color: var(--lowlight);
    animation-timing-function: ease-out;
  }
  
  100% {
    width: var(--coin-size);
    box-shadow:
      0 0 0 var(--side-dark);
  }
}

@keyframes flip {
  0% {
    height: var(--coin-size);
    box-shadow:
      0 0 0 var(--side-dark);
    animation-timing-function: ease-in;
  }
  
  49.999% {
    height: 0.1rem;
    box-shadow:
      0 0.05rem 0 var(--side),
      0 0.1rem 0 var(--side),
      0 0.15rem 0 var(--side),
      0 0.2rem 0 var(--side),
      0 0.25rem 0 var(--side),
      0 0.3rem 0 var(--side),
      0 0.35rem 0 var(--side),
      0 0.4rem 0 var(--side),
      0 0.45rem 0 var(--side),
      0 0.5rem 0 var(--side),
      0 0.55rem 0 var(--side),
      0 0.6rem 0 var(--side),
      0 0.65rem 0 var(--side),
      0 0.7rem 0 var(--side),
      0 0.75rem 0 var(--side);
    transform: translateY(-0.375rem);
    background-color: var(--lowlight);
    animation-timing-function: linear;
  }
  
  50.001% {
    height: 0.1rem;
    box-shadow:
      0 -0.05rem 0 var(--side),
      0 -0.1rem 0 var(--side),
      0 -0.15rem 0 var(--side),
      0 -0.2rem 0 var(--side),
      0 -0.25rem 0 var(--side),
      0 -0.3rem 0 var(--side),
      0 -0.35rem 0 var(--side),
      0 -0.4rem 0 var(--side),
      0 -0.45rem 0 var(--side),
      0 -0.5rem 0 var(--side),
      0 -0.55rem 0 var(--side),
      0 -0.6rem 0 var(--side),
      0 -0.65rem 0 var(--side),
      0 -0.7rem 0 var(--side),
      0 -0.75rem 0 var(--side);
    transform: translateY(0.375rem);
    background-color: var(--lowlight);
    animation-timing-function: ease-out;
  }
  
  100% {
    height: var(--coin-size);
    box-shadow:
      0 0 0 var(--side-dark);
  }
}

.small-card{
  width: 100px;
height: 50px;
/* border: 1px solid teal; */
/* box-shadow: none; */
box-shadow: 0 1px 2px #aaa;
background: teal;
margin: 1em 1em 0em;
border-radius: 3px;
/* user-select: none; */
padding: 0.2em;
/* overflow: hidden; */
font-size: 13px;
color: white;
}
/* end coin */