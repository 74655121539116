
.career-heading{
font-weight: bold;
font-size: 28px;
margin: 10px;
font-family: poppin sans-serif;
}

.career-content{
margin: 40px;
img{
    background-color: #100d31;
    padding: 18px;
    transition: transform .2s; 
}
img:hover {
    transform: scale(1.5); 
  }

}