

.form-container {
	background-color: #fff;
	border-radius: 5px;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
	overflow: hidden;
	width: 400px;
	max-width: 100%;
}

.form {
	padding: 30px 40px;	
}

.form-control {
	margin-bottom: 10px;
	padding-bottom: 20px;
	position: relative;
}

.form-control label {
	display: inline-block;
	margin-bottom: 5px;
}

.form-control input {
	border: 2px solid #f0f0f0;
	border-radius: 4px;
	display: block;
	font-family: inherit;
	font-size: 14px;
	padding: 10px;
	width: 100%;
}

.form-control input:focus {
	outline: 0;
	border-color: #777;
}

.form-control.success input {
	border-color: #2ecc71;
}

.form-control.error input {
	border-color: #e74c3c;
}

.form-control i {
	visibility: hidden;
	position: absolute;
	top: 40px;
	right: 10px;
}

.form-control.success i.fa-check-circle {
	color: #2ecc71;
	visibility: visible;
}

.form-control.error i.fa-exclamation-circle {
	color: #e74c3c;
	visibility: visible;
}

.form-control small {
	color: #e74c3c;
	position: absolute;
	bottom: 0;
	left: 0;
	visibility: hidden;
}

.form-control.error small {
	visibility: visible;
}

.form button{
	background-color: #8e44ad;
	border: 2px solid #8e44ad;
	border-radius: 4px;
	color: #fff;
	display: block;
	font-family: inherit;
	font-size: 16px;
	padding: 10px;
	margin-top: 20px;
	width: 100%;
}
.hsbtn{
    background-color: #8e44ad;
    border: 2px solid #8e44ad;
    border-radius: 4px;
    color: #fff;
    display: block;
    font-family: inherit;
    font-size: 16px;
    padding: 10px;
    margin-top: 20px;
    float: right;
}

/* @import url('https://fonts.googleapis.com/css2?family=josefin+Sans:wght@400;500;600;700&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Josefin Sans', sans-serif;
} */

.card-container {
    min-height: 100vh;
    width: 100%;
    background: #000;
    display: flex;
    align-items: center;
    justify-content: center;
}

.card {
    width: 500px;
    height: 300px;
    color: #fff;
    cursor: pointer;
    perspective: 1000px;
    border: 0px !important;
}

.card-inner {
    width: 100%;
    height: 100%;
    position: relative;
    transition: transform 1s;
    transform-style: preserve-3d;
}

.front, .back {
    width: 500px;
    height: 300px;
    background-image: linear-gradient(45deg, #0045c7, #ff2c7d);
    position: absolute;
    top: 21px;
    left: 0;
    padding: 20px 30px;
    border-radius: 15px;
    overflow: hidden;
    /* z-index: 1; */
    backface-visibility: hidden;
}

.card-number{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.map-img {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.3;
    z-index: -1;
}

.card-no {
    font-size: 35px;
    /* margin-top: 60px; */
}

.card-holder {
    font-size: 12px;
    /* margin-top: 40px; */
}

.name {
    font-size: 18px;
    /* margin-top: 20px; */
}

.bar {
    background: #222;
    margin-left: -30px;
    margin-right: -30px;
    height: 60px;
    margin-top: 10px;
}

.card-cvv {
    margin-top: 20px;
}

.card-cvv div {
    flex: 1;
}

.card-cvv img {
    width: 100%;
    display: block;
    line-height: 0;
}

.card-cvv p {
    background: #fff;
    color: #000;
    font-size: 22px;
    padding: 10px 20px;
}

.card-text {
    margin-top: 30px;
    font-size: 14px;
}

.signature {
    margin-top: 30px;
}

.back {
    transform: rotateY(180deg);
}

.card:hover .card-inner {
    transform: rotateY(-180deg);
}


/* coin */

:root {
    --face: #be9d66;
    --lowlight: #111;
    --side: #896c3b;
    --side-dark: #120e08;
    --coin-size: 7rem;
    --coin-face: url('https://sdbullion.com/media/catalog/product/cache/c687aa7517cf01e65c009f6943c2b1e9/2/0/2019-roaring-lion-silver-coin-obverse-sd-bullion.jpg');
  }
  
  /* html {
    height: 100%;
  }
  
  body {
    height: 100%;
    background-color: silver;
    display: flex;
    justify-content: center;
    align-items: center;
  } */
  
  /*
    everything above is positioning and variables.
    this is where the real fun begins...
  */
  
  .coin {
    height: var(--coin-size);
    width: var(--coin-size);
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .coin::before {
    content: '';
    display: block;
    position: relative;
    height: var(--coin-size);
    width: var(--coin-size);
    border-radius: 50%;
    background-color: var(--face);
    animation: spin 1s linear infinite;
    background-image: var(--coin-face);
    background-size: 100% 100%;
    background-position: center;
    background-blend-mode: overlay;
  }
  
  .coin.flip::before {
    animation-name: flip;
  }
  
  .coin.copper::before {
    filter:
      hue-rotate(-40deg)
      brightness(0.75)
      contrast(115%);
    animation-delay: -0.25s;
  }
  
  .coin.silver::before {
    filter: saturate(0);
    animation-delay: -0.5s;
  }
  
  /* animation definitions */
  
  @keyframes spin {
    0% {
      width: var(--coin-size);
      box-shadow:
        0 0 0 var(--side-dark);
      animation-timing-function: ease-in;
    }
    
    49.999% {
      width: 0.1rem;
      box-shadow:
        0.05rem 0 0 var(--side),
        0.1rem 0 0 var(--side),
        0.15rem 0 0 var(--side),
        0.2rem 0 0 var(--side),
        0.25rem 0 0 var(--side),
        0.3rem 0 0 var(--side),
        0.35rem 0 0 var(--side),
        0.4rem 0 0 var(--side),
        0.45rem 0 0 var(--side),
        0.5rem 0 0 var(--side),
        0.55rem 0 0 var(--side),
        0.6rem 0 0 var(--side),
        0.65rem 0 0 var(--side),
        0.7rem 0 0 var(--side),
        0.75rem 0 0 var(--side);
      transform: translateX(-0.375rem);
      background-color: var(--lowlight);
      animation-timing-function: linear;
    }
    
    50.001% {
      width: 0.1rem;
      box-shadow:
        -0.05rem 0 0 var(--side),
        -0.1rem 0 0 var(--side),
        -0.15rem 0 0 var(--side),
        -0.2rem 0 0 var(--side),
        -0.25rem 0 0 var(--side),
        -0.3rem 0 0 var(--side),
        -0.35rem 0 0 var(--side),
        -0.4rem 0 0 var(--side),
        -0.45rem 0 0 var(--side),
        -0.5rem 0 0 var(--side),
        -0.55rem 0 0 var(--side),
        -0.6rem 0 0 var(--side),
        -0.65rem 0 0 var(--side),
        -0.7rem 0 0 var(--side),
        -0.75rem 0 0 var(--side);
      transform: translateX(0.375rem);
      background-color: var(--lowlight);
      animation-timing-function: ease-out;
    }
    
    100% {
      width: var(--coin-size);
      box-shadow:
        0 0 0 var(--side-dark);
    }
  }
  
  @keyframes flip {
    0% {
      height: var(--coin-size);
      box-shadow:
        0 0 0 var(--side-dark);
      animation-timing-function: ease-in;
    }
    
    49.999% {
      height: 0.1rem;
      box-shadow:
        0 0.05rem 0 var(--side),
        0 0.1rem 0 var(--side),
        0 0.15rem 0 var(--side),
        0 0.2rem 0 var(--side),
        0 0.25rem 0 var(--side),
        0 0.3rem 0 var(--side),
        0 0.35rem 0 var(--side),
        0 0.4rem 0 var(--side),
        0 0.45rem 0 var(--side),
        0 0.5rem 0 var(--side),
        0 0.55rem 0 var(--side),
        0 0.6rem 0 var(--side),
        0 0.65rem 0 var(--side),
        0 0.7rem 0 var(--side),
        0 0.75rem 0 var(--side);
      transform: translateY(-0.375rem);
      background-color: var(--lowlight);
      animation-timing-function: linear;
    }
    
    50.001% {
      height: 0.1rem;
      box-shadow:
        0 -0.05rem 0 var(--side),
        0 -0.1rem 0 var(--side),
        0 -0.15rem 0 var(--side),
        0 -0.2rem 0 var(--side),
        0 -0.25rem 0 var(--side),
        0 -0.3rem 0 var(--side),
        0 -0.35rem 0 var(--side),
        0 -0.4rem 0 var(--side),
        0 -0.45rem 0 var(--side),
        0 -0.5rem 0 var(--side),
        0 -0.55rem 0 var(--side),
        0 -0.6rem 0 var(--side),
        0 -0.65rem 0 var(--side),
        0 -0.7rem 0 var(--side),
        0 -0.75rem 0 var(--side);
      transform: translateY(0.375rem);
      background-color: var(--lowlight);
      animation-timing-function: ease-out;
    }
    
    100% {
      height: var(--coin-size);
      box-shadow:
        0 0 0 var(--side-dark);
    }
  }
  
  .small-card{
    width: 100px;
  height: 50px;
  /* border: 1px solid teal; */
  /* box-shadow: none; */
  box-shadow: 0 1px 2px #aaa;
  background: teal;
  margin: 1em 1em 0em;
  border-radius: 3px;
  /* user-select: none; */
  padding: 0.2em;
  /* overflow: hidden; */
  font-size: 13px;
  color: white;
  }